
  // IMPORTANT: This file was generated by tools/build.js
  // If you edit it, your changes will be overwritten once dev server restarts
  export default {
  "CONFIG_ID": "platformsh:prod",
  "CUSTOM_APP_NAME": false,
  "CUSTOM_ASSETS_URL": false,
  "CUSTOM_AUTH_LEFT_PANEL": [
    "/register"
  ],
  "CUSTOM_BLACKFIRE_CARD_LANGUAGE": [
    "php",
    "python"
  ],
  "CUSTOM_BRANDING_MESSAGE": false,
  "CUSTOM_CLI_COMMAND": false,
  "CUSTOM_CONFIG_DIR_NAME": false,
  "CUSTOM_CONSOLE_ID": false,
  "CUSTOM_CONSOLE_TITLE": false,
  "CUSTOM_CSP": "https://geolocation.onetrust.com/cookieconsentpub/v1/geo/location",
  "CUSTOM_DEFAULT_BRANCH": "main",
  "CUSTOM_FEDERATED_AUTH_PROVIDERS": [
    {
      "label": "GitHub",
      "name": "github",
      "imagePath": "images/github.svg",
      "imagePathDark": "images/github-white.svg",
      "loginUrl": "https://auth.api.platform.sh/auth/authorize/github?intent=login",
      "signupUrl": "https://auth.api.platform.sh/auth/authorize/github?intent=signup",
      "connectUrl": "https://auth.api.platform.sh/auth/authorize/github?intent=connect"
    },
    {
      "label": "Google",
      "name": "google",
      "imagePath": "images/google.svg",
      "loginUrl": "https://auth.api.platform.sh/auth/authorize/google?intent=login",
      "signupUrl": "https://auth.api.platform.sh/auth/authorize/google?intent=signup",
      "connectUrl": "https://auth.api.platform.sh/auth/authorize/google?intent=connect"
    },
    {
      "label": "Bitbucket",
      "name": "bitbucket",
      "imagePath": "images/bitbucket.svg",
      "loginUrl": "https://auth.api.platform.sh/auth/authorize/bitbucket?intent=login",
      "signupUrl": "https://auth.api.platform.sh/auth/authorize/bitbucket?intent=signup",
      "connectUrl": "https://auth.api.platform.sh/auth/authorize/bitbucket?intent=connect"
    },
    {
      "label": "GitLab",
      "name": "gitlab",
      "imagePath": "images/gitlab.svg",
      "loginUrl": "https://auth.api.platform.sh/auth/authorize/gitlab?intent=login",
      "signupUrl": "https://auth.api.platform.sh/auth/authorize/gitlab?intent=signup",
      "connectUrl": "https://auth.api.platform.sh/auth/authorize/gitlab?intent=connect"
    }
  ],
  "CUSTOM_FOOTER_TEXT": false,
  "CUSTOM_LOGO_HEIGHT": false,
  "CUSTOM_LOGO_WIDTH": false,
  "CUSTOM_ORGANIZATION_VENDORS": {
    "platformsh": "",
    "upsun": "https://console.upsun.com"
  },
  "CUSTOM_THEME": false,
  "CUSTOM_TRIAL_STAGES": [
    {
      "daysRemaining": 10,
      "msg": "state_1",
      "priority": "information",
      "tagText": "free_trial",
      "tagVariant": "rose"
    },
    {
      "daysRemaining": 1,
      "msg": "state_2",
      "priority": "information",
      "tagText": "trial_ending",
      "tagVariant": "rose"
    },
    {
      "daysRemaining": 0,
      "msg": "state_3",
      "priority": "high",
      "tagText": "suspended",
      "tagVariant": "red"
    }
  ],
  "CUSTOM_URL_SUPPORT": false,
  "CUSTOM_WINDOW_TITLE": "Platform.sh",
  "CUSTOM_PROFILING_LANGUAGE": [
    "php",
    "python",
    "golang"
  ],
  "CUSTOM_SIMPLE_LEFT_PANEL": [
    "/"
  ],
  "CUSTOM_COOKIE_DOMAIN": "platform.sh",
  "CUSTOM_URL_BILLING": false,
  "CUSTOM_URL_CLI_DOWNLOAD": false,
  "CUSTOM_URL_DOCS": false,
  "CUSTOM_URL_DOCS_STATUS_CHECK": false,
  "CUSTOM_URL_PROJECT_CREATION": false,
  "CUSTOM_URL_SUBSCRIPTION_DELETE_REDIRECT": false,
  "CUSTOM_URL_SUBSCRIPTION_UPGRADE": false,
  "CUSTOM_URL_VENDOR_CONSOLE": false,
  "URL_ACCOUNTS": "https://accounts.platform.sh",
  "URL_ANNOUNCEMENTS": "https://platform.sh/product/announcements/index.json",
  "URL_API_BLACKFIRE": "https://api.blackfire.io",
  "URL_API_PROXY": "https://api.platform.sh/api",
  "URL_AUTH": "https://auth.api.platform.sh",
  "URL_GITHUB": "https://api.github.com",
  "URL_GITLAB": "https://gitlab.com",
  "URL_LOGOUT": "https://accounts.platform.sh/user/logout",
  "URL_REDIRECT_AFTER_LOGIN": false,
  "URL_REDIRECT_AUTH_USER": "https://console.platform.sh",
  "URL_SCREENSHOT_API": "https://screenshots.platform.sh/screenshot",
  "URL_SIA": false,
  "URL_ZENDESK": "https://support.platform.sh/hc/en-us/requests",
  "GA_PROPERTY_ID": "UA-4064131-16",
  "ID_API_CLIENT": "console.platform.sh",
  "ID_COOKIELAW": "13a2221f-a3c7-492f-82fa-c96116e83517",
  "ID_DRIFT_CHATBOT": false,
  "ID_GOOGLE_ANALYTICS": "UA-4064131-16",
  "ID_GOOGLE_TAG_MANAGER": "GTM-MR3BJL",
  "ID_HEAP": "816119933",
  "ID_PLATFORM_TREE": false,
  "ID_RECAPTCHA": "6LdtXdUUAAAAACC112Rhevm2V0WMcgVkvVbPEJDI",
  "ID_STATUS_PAGE": "l2x5n4czt1ph",
  "ID_ZENDESK": "e0bcb0cf-9496-44be-9c98-8728145585f3",
  "ID_SENTRY_DNS": "https://7682ebc48eef70826bdca7ac9f08b505@o38989.ingest.us.sentry.io/4508160398852096",
  "ID_SENTRY_ORGANIZATION": "platformsh",
  "ID_SENTRY_PROJECT": "console",
  "ENABLE_SENTRY": true,
  "DISABLE_ALL_PROJECTS": true,
  "ENABLE_ACCOUNT_PAGES": true,
  "ENABLE_ACCOUNT_SECURITY_ROUTE": true,
  "ENABLE_ADD_DOMAIN_SUPPORT": false,
  "ENABLE_ADD_PROJECT_USER_PRICING_AGREEMENT": true,
  "ENABLE_ANNOUNCEMENTS": true,
  "ENABLE_AUTOMATED_BACKUPS": true,
  "ENABLE_BILLING": true,
  "ENABLE_BLACKFIRE_CARD": true,
  "ENABLE_CHANGE_USERNAME_EMAIL": true,
  "ENABLE_CONNECTED_ACCOUNTS_ROUTE": true,
  "ENABLE_CREATE_ORGANIZATION": true,
  "ENABLE_CREATE_PROJECT": true,
  "ENABLE_CUSTOM_LEFT_PANEL": false,
  "ENABLE_DEPLOYMENT_OVERLAY": true,
  "ENABLE_DISCLAIMER_GENERAL_SUPPORT": true,
  "ENABLE_EDIT_PLAN": true,
  "ENABLE_ENVIRONMENT_DOMAINS": true,
  "ENABLE_ENVIRONMENT_EMAIL_SETTING": true,
  "ENABLE_ENVIRONMENT_SOCKET": true,
  "ENABLE_EXTERNAL_URL": false,
  "ENABLE_FIRST_PROJECT_INCENTIVE": false,
  "ENABLE_FREE_TRIAL_DIALOG": false,
  "ENABLE_GREEN_DISCOUNT": false,
  "ENABLE_HIPAA_COMPLIANCE": false,
  "ENABLE_HTTP_METRICS": false,
  "ENABLE_LOCAL_DEPLOYMENT_TARGET": false,
  "ENABLE_MARKETING_CHECKBOX": true,
  "ENABLE_MIGRATION_FLOW": false,
  "ENABLE_NOTIFICATION_SETTINGS": true,
  "ENABLE_OBSERVABILITY_SANDBOX": true,
  "ENABLE_OBSERVABILITY_SUITE_INTEGRATIONS": true,
  "ENABLE_ORGANIZATION": true,
  "ENABLE_ORGANIZATION_FIRST_ONBOARDING": false,
  "ENABLE_ORGANIZATION_PLANS_PERMISSION": true,
  "ENABLE_PROJECT_ACCESS_SELECT": false,
  "ENABLE_PROJECT_PROVISIONING_WIZARD": false,
  "ENABLE_PROJECT_SETUP_TIPS": false,
  "ENABLE_PROJECT_SETUP_WIZARD": true,
  "ENABLE_REGISTRATION": true,
  "ENABLE_RESET_PROFILE_PASSWORD": true,
  "ENABLE_RESOURCE_METRICS_THRESHOLD": true,
  "ENABLE_SERVICE_TREE": true,
  "ENABLE_SINGLE_STEP_SINGUP_FLOW": false,
  "ENABLE_SOURCE_OPERATION": true,
  "ENABLE_SOURCE_REPOSITORY_TOOLTIP": false,
  "ENABLE_SSO": true,
  "ENABLE_STAFF_TEST_MODE": true,
  "ENABLE_STRIPE": true,
  "ENABLE_SUBSCRIBE_FLOW": false,
  "ENABLE_SUPPORT_SELLABLE": false,
  "ENABLE_TERMS_AND_ACCEPTANCE_CHECKBOX": true,
  "ENABLE_TOTAL_RESOURCE_ALLOCATION_METRICS": false,
  "ENABLE_UNBRANDED_ILLUSTRATIONS": false,
  "ENABLE_USAGE_BASED_BILLING_PAGES": false,
  "ENABLE_USER_MANAGEMENT": true,
  "ENABLE_VOUCHERS": true,
  "ENABLE_CONTINUOUS_PROFILING_SELLABLE": false,
  "ENABLE_PROFILING": true,
  "ENABLE_ZENDESK": true,
  "ENABLE_CUSTOM_BANNER_MESSAGE": false,
  "ENABLE_REACTIVATE_TRIAL": false,
  "VERSION_NUMBER": "3.2.57"
}