import styled, { css as styledCSS } from "styled-components";

import { Priority, Variant } from "./Sticker.types";

const PRIORITIES = {
  [Priority.information]: {
    color: "var(--theme-neutral-900)",
    background: "var(--theme-primary-100)",
    border: "var(--theme-primary-600)"
  },
  [Priority.low]: {
    color: "var(--theme-neutral-900)",
    background: "var(--theme-success-200)",
    border: "var(--theme-success-600)"
  },
  [Priority.normal]: {
    color: "var(--theme-neutral-900)",
    background: "var(--theme-warning-200)",
    border: "var(--theme-warning-600)"
  },
  [Priority.critical]: {
    color: "var(--theme-neutral-900)",
    background: "var(--theme-critical-200)",
    border: "var(--theme-critical-600)"
  }
};

const dash = (color: string, direction: "bottom" | "right") => `
    repeating-linear-gradient(
      to ${direction},
      ${color} 0%,
      ${color} 35%,
      transparent 35%,
      transparent 55%,
      ${color} 55%,
      ${color} 75%,
      transparent 75%,
      transparent 100%
    )
  `;

const getCustomBorder = (color: string) => styledCSS`
  background-image: ${dash(color, "right")}, ${dash(color, "right")},
    ${dash(color, "bottom")}, ${dash(color, "bottom")};
`;

const variantShort = styledCSS`
  word-break: break-all;
`;

const variantButton = styledCSS`
  display: inline-flex;
  width: 100%;
`;

const variantLong = styledCSS`
  display: inline-flex;
  align-items: center;
  width: 100%;
`;

export interface RootProps {
  priority?: keyof typeof Priority;
  variant?: keyof typeof Variant;
  css?: { [key: string]: number | string };
}

const Root = styled.div<RootProps>`
  display: flex;
  align-items: center;
  gap: 16px;
  font-family: Open Sans, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  box-sizing: border-box;
  padding: 10px 16px;
  border-radius: 4px;
  margin-bottom: 8px;
  background: ${({ priority }) =>
    PRIORITIES[priority || Priority.information].background};
  ${({ priority }) =>
    getCustomBorder(`${PRIORITIES[priority || Priority.information].border}`)};
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 24px 1px, 24px 1px, 1px 24px, 1px 24px;
  color: var(--theme-neutral-900);

  ${({ variant }) => {
    switch (variant) {
      case Variant.button:
        return variantButton;
      case Variant.long:
        return variantLong;
      case Variant.short:
      default:
        return variantShort;
    }
  }}
`;

export default Root;
