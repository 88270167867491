import { createAsyncThunk } from "@reduxjs/toolkit";

import { transformEnvironmentsForAPI } from "Reducers/access/access.utils";
import { AsyncThunkOptionType } from "Reducers/types";

import { AddInvitationCallback } from "./addInvitation.thunk";

export const resendInvitationThunk = createAsyncThunk<
  undefined,
  AddInvitationCallback,
  AsyncThunkOptionType
>(
  "app/invitation/resend",
  async (
    { email, projectId, adminRights, environmentTypes },
    { rejectWithValue }
  ) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    // The API requires this flag to be true to resend an invitation
    // instead of creating one
    const force = true;

    try {
      await client.createInvitationWithEnvironmentTypes(
        email,
        projectId,
        adminRights ? "admin" : "viewer",
        transformEnvironmentsForAPI(environmentTypes),
        force
      );
    } catch (err) {
      return rejectWithValue("unknown error when resending a new invitation");
    }
  }
);
