import React, { useState } from "react";

import { FormattedMessage } from "react-intl";

import * as Toast from "ds/Toast";
import { organizationHasDunningEventsSelector } from "Reducers/organization/dunning";
import { useAppSelector } from "Store/hooks";

import * as S from "./DunningTag.styles";

const DunningTag = ({
  organizationName,
  status
}: {
  organizationName: string;
  status?: string;
}) => {
  const hasDunningEvents = useAppSelector(state =>
    organizationHasDunningEventsSelector(state, { organizationName })
  );
  const [isOpenToast, setIsOpenToast] = useState(false);

  return hasDunningEvents &&
    (["restricted", "suspended", "deleted"] as typeof status[]).includes(
      status
    ) ? (
    <S.Layout>
      <S.Tag
        variant={status === "restricted" ? "yellow" : "red"}
        onMouseEnter={() => setIsOpenToast(true)}
      >
        <FormattedMessage
          id={status === "restricted" ? "restricted" : "suspended"}
        />
      </S.Tag>
      <Toast.Provider duration={Infinity}>
        <Toast.Root open={isOpenToast} onOpenChange={setIsOpenToast}>
          <Toast.Content>
            <Toast.Title>
              <FormattedMessage id={`tooltip.dunning.${status}.title`} />
            </Toast.Title>

            <S.ToastDescription>
              <FormattedMessage
                id={`tooltip.dunning.${status}.description`}
                values={{
                  p: chunks => <p>{chunks}</p>,
                  ul: chunks => <ul>{chunks}</ul>,
                  li: chunks => <li>{chunks}</li>,
                  linkBilling: chunks => (
                    <a href={`/${organizationName}/-/billing/details`}>
                      {chunks}
                    </a>
                  )
                }}
              />
            </S.ToastDescription>

            <Toast.Close aria-label="Close" />
          </Toast.Content>
        </Toast.Root>
        <S.ToastViewport />
      </Toast.Provider>
    </S.Layout>
  ) : null;
};

export default DunningTag;
