import React from "react";

import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

type LoadingIconPropType = {
  size?: number;
  strokeBackground?: string;
};

const LoadingIcon = ({
  size = 40,
  strokeBackground = "#cee0fe"
}: LoadingIconPropType) => {
  const uid = useUniqueId();

  return (
    <svg
      className="icon-loading"
      width={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      preserveAspectRatio="xMidYMid"
      style={{ background: "none" }}
      role="img"
      aria-labelledby={`loading-icon-${uid}`}
    >
      <title id={`loading-icon-${uid}`}>Loading icon</title>
      <circle
        cx="20"
        cy="20"
        ng-attr-r="{{config.radius}}"
        ng-attr-stroke="{{config.base}}"
        ng-attr-stroke-width="{{config.width}}"
        fill="none"
        r="10"
        stroke={strokeBackground}
        strokeWidth="8"
      />
      <circle
        cx="20"
        cy="20"
        ng-attr-r="{{config.radius}}"
        ng-attr-stroke="{{config.stroke}}"
        ng-attr-stroke-width="{{config.innerWidth}}"
        ng-attr-stroke-linecap="{{config.linecap}}"
        fill="none"
        r="10"
        stroke="#4786ff"
        strokeWidth="2"
        strokeLinecap="round"
        transform="rotate(177.406 20 20)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 20 20;180 20 20;720 20 20"
          keyTimes="0;0.5;1"
          dur="1.6s"
          begin="0s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="stroke-dasharray"
          calcMode="linear"
          values="6.283185307179586 56.548667764616276;50.26548245743669 12.566370614359172;6.283185307179586 56.548667764616276"
          keyTimes="0;0.5;1"
          dur="1.6"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};

LoadingIcon.propTypes = {
  color: PropTypes.string
};

export default LoadingIcon;
