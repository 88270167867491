import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styled, { css as styledCSS } from "styled-components";

import { Input as RadioPrimitive } from "ds/Radio";
import { largeShadow } from "ds/Shadows";

const padding = 8;

export const Content = styled(DropdownMenu.Content).attrs(props => ({
  align: props.align || "start",
  onCloseAutoFocus: event => event?.preventDefault()
}))`
  box-sizing: border-box;
  background-color: var(--mode-bg-neutral-weakest);
  margin-top: 4px;
  padding: ${padding}px;
  font-family: Open Sans, sans-serif;
  min-width: 220px;
  border-radius: 2px;
  ${largeShadow}
  position: relative;
  z-index: 1001;
`;

export const Label = styled(DropdownMenu.Label)`
  padding: 8px 16px;
  color: var(--mode-text-neutral-weakest);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: left;
`;

export const Radio = styled(RadioPrimitive)`
  margin-bottom: 0;
  flex: 1;
`;

export const ItemIcon = styled.span`
  svg,
  [fill] {
    fill: var(--mode-vector-neutral-default);
  }
`;

export const __disabled_styles = styledCSS`
  color: var(--mode-text-neutral-weakest-disabled);
  cursor: not-allowed;
  :hover,
  :focus {
    background-color: var(--mode-bg-primary-weakest);
    color: var(--mode-text-primary-default);
  }
`;

export const item = styledCSS<{ disabled?: boolean }>`
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: var(--mode-text-neutral-default-weak);
  border: 1px solid transparent;
  background-color: transparent;
  text-align: left;
  outline: none;
  border-radius: 4px;
  transition: 0.25s;
  cursor: pointer;

  display: flex;
  box-sizing: border-box;
  height: 32px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  white-space: nowrap;

  :hover {
    background-color: var(--mode-bg-primary-weakest);
    color: var(--mode-text-primary-default);

    ${ItemIcon} {
      fill: var(--mode-vector-primary-default);
    }
  }

  :focus:not(:hover) {
    border: 1px solid var(--mode-neutral-border-weak-default);
    border-radius: 2px;
    background-color: var(--mode-bg-primary-weakest);
    color: var(--mode-text-primary-default);
  }

  ${({ disabled }) => disabled && __disabled_styles};
`;

export const FlatItem = styled.div`
  padding: 8px;
`;

export const ItemLabel = styled.span`
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: var(--mode-text-neutral-default-weak);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 4px;
`;

export const Separator = styled(DropdownMenu.Separator)`
  height: 1px;
  background-color: var(--mode-border-neutral-weakest);
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: -${padding}px;
  margin-right: -${padding}px;
`;
