import React, { ComponentProps } from "react";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as LabelPrimitive from "@radix-ui/react-label";
import styled, { css as styledCSS } from "styled-components";

export const CheckboxWrapper = styled.div`
  /* The height of this wrapper should be the same as the lanbel's line height so it remains always aligned with the first line of the label regardless of how many lines there are. */
  height: 24px;
  display: flex;
  align-items: center;
`;

export const Root = styled(
  ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isValid,
    ...props
  }: { isValid?: boolean } & ComponentProps<typeof CheckboxPrimitive.Root>) => (
    <CheckboxPrimitive.Root {...props} />
  )
)<{
  isValid?: boolean;
}>`
  height: 18px;
  min-width: 18px;
  max-width: 18px;
  border-radius: 2px;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: var(--mode-border-neutral-default);
  opacity: 1;
  box-shadow: none;
  position: relative;
  cursor: pointer;
  outline: none;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  :hover {
    border-color: var(--mode-border-primary-strong);
  }

  :focus,
  :active {
    border-color: var(--mode-border-primary-strong);
    box-shadow: 0px 0px 2px var(--theme-primary-800),
      inset 0 0 2px var(--theme-primary-800);
  }

  :disabled {
    box-shadow: none;
    opacity: 0.5;
    cursor: not-allowed;
  }

  &[data-state="checked"] {
    border-color: var(--mode-border-primary-default);
  }

  :focus&[data-state="checked"] {
    border-color: var(--mode-border-primary-strong);
  }

  ${({ isValid }) =>
    !isValid &&
    styledCSS`
      border-color: var(--mode-border-critical-default);

      &[data-state="checked"] {
        border-color: var(--mode-border-critical-default);
      }
    `};
`;

interface LabelProps {
  disabled?: boolean;
}

export const Label = styled(LabelPrimitive.Root)<LabelProps>`
  font-family: "Open Sans";
  cursor: pointer;
  width: 100%;
  height: fit-content;
  font-size: 15px;
  line-height: 24px;
  color: var(--mode-text-neutral-default-weak);
  font-weight: 400;
  padding-left: 12px;
  ${({ disabled }) =>
    disabled &&
    styledCSS`
      cursor: not-allowed;
      opacity: 0.5;
  `};
`;

export const Indicator = styled(CheckboxPrimitive.Indicator)`
  display: flex;
  height: 10px;
  width: 10px;
`;

export const Layout = styled.div`
  margin-bottom: 8px;
  display: flex;
  outline: none;
  align-items: flex-start;
`;

export const Error = styled.div`
  font-weight: 400;
  color: var(--mode-text-neutral-default);
  font-size: 13px;

  &:first-letter {
    text-transform: uppercase;
  }
`;
