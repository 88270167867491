import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import styled, { css as styledCSS } from "styled-components";

import { __button_outline } from "ds/Button/Button.styles";
import ChevronIcon from "ds/Icons/Chevron";

export const Chevron = styled(ChevronIcon)``;

export interface DropdownMenuTriggerProps {
  variant?: "icon" | "mixed" | "outline" | "large" | "picklist";
  isActive?: boolean;
}

export interface DropdownMenuContentProps {
  border?: boolean;
}

const iconTrigger = styledCSS`
  height: 32px;
  width: 32px;
  padding: 0;

  > svg {
    display: inline-block;
    height: 24px;
    width: 24px;
  }

  svg {
    color: currentColor;
  }

  svg * {
    fill: currentColor;
  }

  padding-left: 24px
  padding-right: 24px;
  background-color: transparent;
  color: var(--mode-vector-neutral-default);

  :focus {
    ${__button_outline};
  }

  :hover {
    background-color: var(--mode-bg-neutral-weaker);
  }

  :active {
    border-color: transparent;
    box-shadow: none;
    background-color: var(--mode-bg-primary-weaker);
  }

`;

const outlineTrigger = styledCSS`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--mode-text-neutral-default-weak);

  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 16px;
  padding-right: 8px;
  background-color: transparent;

  border: 1px solid var(--mode-border-neutral-default);
  box-sizing: border-box;
  border-radius: var(--trigger-button-border-radius,var(--button-2px-border-radius,var(--2px)));

  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  > svg {
    display: inline-block;
    height: 24px;
    width: 24px;
  }

  svg {
    color: currentColor;
  }

  svg * {
    fill: currentColor;
  }

  :focus {
    ${__button_outline};
  }

  :focus,
  :hover {
    color: var(--mode-text-primary-default);
  }

  :active:not(:disabled) {
    color: var(--mode-text-primary-default);
    border-color: var(--mode-border-primary-strong);
  }

  :disabled {
    pointer-events: none;
    color: var(--mode-text-neutral-weakest-disabled);
    border-color: var(--mode-border-neutral-default);
  }
`;

const mixedTrigger = styledCSS`
  background-color: transparent;
  padding: 4px 8px;
  gap: 8px;
  color: var(--mode-text-neutral-weakest);

  svg,
  [fill] {
    fill: currentColor;
    transition-duration: 0.25s;
    transition-property: fill;
  }

  :focus,
  :hover {
    color: var(--mode-text-primary-default);
  }

  &[data-state="open"] {
    color: var(--mode-text-primary-strong);
  }
`;

export const largeTrigger = styledCSS<DropdownMenuTriggerProps>`
  padding-top: 8px;
  padding-right: 9px;
  padding-bottom: 8px;
  padding-left: 24px;
  height: 56px;
  min-width: 207px;
  background-color: transparent;
  justify-content: space-between;
  gap: 8px;
  border-radius: 0;
  position: relative;
  :after {
      content: "";
      display: block;
      height: 100%;
      border-right: 1px solid var(--mode-vector-neutral-weakest);
      opacity: 0.1;
      top: 0;
      right: 0;
      position: absolute;
      width: 1px;
    }
  @media (min-width: 768px) {
    min-width: 230px;
  }

  ${({ isActive }) =>
    isActive &&
    styledCSS`
      background-color: var(--theme-neutral-800);
    `};

  &[data-state="open"],
  :hover {
    background-color: var(--theme-neutral-800);
  }

`;

export const picklistTrigger = styledCSS<DropdownMenuTriggerProps>`
  height: 24px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  background-color: transparent;
  padding-left: 8px;
  padding-right: 4px;
  color: var(--mode-text-neutral-weakest);

  &[data-state="open"],
  :hover {
    color: var(--mode-text-primary-default);
  }

  :focus {
    color: var(--mode-text-neutral-weakest);
    ${__button_outline};
  }

  :disabled {
    color: var(--mode-tex-neutral-inverse-weak);
  }

  svg, [fill] {
    fill: currentColor;
  }

  ${Chevron} {
    margin-left: 8px;
  }
`;

export const TriggerLabel = styled.span`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: var(--mode-text-neutral-weakest);
`;

export const TriggerName = styled.span`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: var(--theme-neutral-0);
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: 156px;
`;

export const TriggerContent = styled.span<DropdownMenuContentProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  position: relative;

  :after {
    content: "";
    display: block;
    height: 24px;
    border: ${({ border }) =>
      border ? `1px solid ${"var(--mode-vector-neutral-weakest)"}` : null};
    opacity: 0.1;
    top: 8px;
    right: 0;
    position: absolute;
  }
`;

export const Image = styled.img`
  width: 24px;
  height: 24px;
`;

export const Trigger = styled(DropdownMenu.Trigger)<DropdownMenuTriggerProps>`
  box-sizing: border-box;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  mix-blend-mode: normal;
  border-radius: var(
    --trigger-button-border-radius,
    var(--button-2px-border-radius, var(--2px))
  );
  cursor: pointer;

  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  outline: none;
  border: 1px solid transparent;
  padding: 0;
  margin: 0;

  transition-duration: 0.25s;
  transition-property: color, background-color, border-color, box-shadow;

  ${({ variant }) => (!variant || variant === "icon") && iconTrigger};
  ${({ variant }) => variant === "outline" && outlineTrigger};
  ${({ variant }) => variant === "mixed" && mixedTrigger};
  ${({ variant }) => variant === "large" && largeTrigger};
  ${({ variant }) => variant === "picklist" && picklistTrigger};

  &[data-state="open"] ${Chevron} {
    transform: rotate(180deg);
  }
`;
