import { createAsyncThunk } from "@reduxjs/toolkit";

import logger from "Libs/logger";
import { AsyncThunkOptionType } from "Reducers/types";

import type {
  Environment,
  EnvironmentDomain,
  Project
} from "platformsh-client";

export const getEnvironmentDomainsThunk = createAsyncThunk<
  EnvironmentDomain[] | undefined,
  { project: Project; environment?: Environment },
  AsyncThunkOptionType<{ error: Error }>
>(
  "app/project/environment/domains/get",
  async ({ project, environment }, { rejectWithValue }) => {
    try {
      const encodedEnvironmentId = encodeURIComponent(
        environment ? environment?.id : ""
      );

      const environmentDomains = await project.getEnvironmentDomains(
        encodedEnvironmentId
      );
      return environmentDomains;
    } catch (err) {
      if (err instanceof Error) {
        logger(err.message, {
          action: "get_environment_domains",
          meta: {
            projectId: project.id
          }
        });
        return rejectWithValue({ error: err });
      }
    }
  }
);
