import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  createSelector
} from "@reduxjs/toolkit";

import { ENVIRONMENT_TYPES } from "Constants/constants";
import logger from "Libs/logger";
import { setDeep } from "Libs/objectAccess";
import { hasHtml } from "Libs/utils";
import { getCommonError } from "Reducers/sliceFactory";
import { AsyncThunkOptionType, CommonErrorType } from "Reducers/types";
import { RootState } from "Store/configureStore";

import type { Activity, Environment } from "platformsh-client";

export const loadEnvironments = createAsyncThunk<
  Environment[],
  { organizationId: string; projectId: string },
  AsyncThunkOptionType
>(
  "app/project/environments",
  async ({ organizationId, projectId }, { rejectWithValue }) => {
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const environments = await client.getEnvironments(projectId);
      return environments;
    } catch (err: any) {
      if (![404, 403].includes(err.code) && !hasHtml(err)) {
        logger(
          {
            errMessage: err,
            organizationId,
            projectId
          },
          {
            action: "environments_load"
          }
        );
      }

      return rejectWithValue({ errors: err.detail });
    }
  },
  {
    condition: ({ organizationId, projectId }) =>
      // If both values are true then continue with the thunk
      !!organizationId && !!projectId
  }
);

export const loadEnvironment = createAsyncThunk<
  Environment,
  { organizationId: string; projectId: string; environmentId: string },
  AsyncThunkOptionType
>(
  "app/project/environment",
  async ({ organizationId, projectId, environmentId }, { rejectWithValue }) => {
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const environment = await client.getEnvironment(
        projectId,
        encodeURIComponent(environmentId)
      );
      return environment;
    } catch (err: any) {
      if (![403, 404].includes(err.code)) {
        logger(
          {
            errMessage: err.message,
            organizationId,
            projectId,
            environmentId
          },
          {
            action: "environment_load"
          }
        );
      }
      return rejectWithValue({ errors: err.detail });
    }
  }
);

export const updateEnvironment = createAsyncThunk<
  Environment,
  {
    organizationId: string;
    projectId: string;
    environmentId: string;
    data: Partial<Environment>;
  },
  AsyncThunkOptionType
>(
  "app/project/environment/update",
  async (
    { organizationId, projectId, environmentId, data },
    { getState, rejectWithValue }
  ) => {
    try {
      const environment = environmentSelector(getState(), {
        organizationId,
        projectId,
        environmentId
      });

      if (!environment)
        return rejectWithValue({ errors: "Environment not found" });

      const result = await environment.update(data);
      const newEnvironment: Environment = await result.getEntity();
      return newEnvironment;
    } catch (err: any) {
      logger(
        {
          errMessage: err.message,
          environmentId
        },
        {
          action: "environment_update"
        }
      );
      return rejectWithValue({ errors: err });
    }
  }
);

export const toggleEnvironmentActivation = createAsyncThunk<
  Activity,
  {
    organizationId: string;
    projectId: string;
    environmentId: string;
    action?: "deactivate" | "activate";
  },
  AsyncThunkOptionType
>(
  "app/project/environment/activation/toggle",
  async (
    { organizationId, projectId, environmentId, action },
    { getState, rejectWithValue }
  ) => {
    const state = getState();

    const environment = environmentSelector(state, {
      organizationId,
      projectId,
      environmentId
    });

    if (!environment) {
      return rejectWithValue({ errors: "Environment not found" });
    }

    try {
      const isActive = environment.isActive();
      let activity = await environment[
        action ? action : isActive ? "deactivate" : "activate"
      ]();
      activity = await activity.wait();

      if (activity.result === "failure") {
        return rejectWithValue({ errors: activity.log });
      }
      return activity;
    } catch (err: any) {
      if (![404, 403].includes(err.code) && !hasHtml(err)) {
        logger(
          {
            errMessage: err.message,
            organizationId,
            projectId,
            environmentId
          },
          {
            action: "toggleEnvironmentActivation"
          }
        );
      }
      return rejectWithValue({ errors: err.detail });
    }
  }
);

type Treebranch = { id: string; depth: number; path: string[] };

export const deleteEnvironment = createAsyncThunk<
  undefined,
  { organizationId: string; projectId: string; environmentId: string },
  AsyncThunkOptionType
>(
  "app/project/environment/delete",
  async (
    { organizationId, projectId, environmentId },
    { getState, rejectWithValue }
  ) => {
    const environment = environmentSelector(getState(), {
      organizationId,
      projectId,
      environmentId
    });

    if (!environment) {
      return rejectWithValue({ errors: "Environment not found" });
    }
    try {
      await environment.delete();
      return;
    } catch (err: any) {
      logger(
        {
          errMessage: err.message,
          environmentId
        },
        {
          action: "environment_delete"
        }
      );
      return rejectWithValue({ errors: err.detail });
    }
  }
);

type EnvironmentState = {
  data?: Record<
    string,
    Record<string, Record<string, Environment> | undefined> | undefined
  >;
  errors?: {
    project?: Record<string, unknown>;
    environment?: Record<string, Record<string, unknown> | undefined>;
    environmentUpdate?: Record<string, Record<string, unknown> | undefined>;
    environmentDelete?: Record<string, Record<string, unknown> | undefined>;
  };
  loading?: {
    project?: Record<string, boolean>;
    environment?: Record<string, Record<string, boolean> | undefined>;
    toggleActivation?: Record<string, Record<string, boolean> | undefined>;
  };
  tree?: Record<
    string,
    Record<string, Record<string, Treebranch> | undefined> | undefined
  >;
  deleted?: {
    environment?: Record<string, Record<string, boolean> | undefined>;
  };
  toggleActivation?: Record<string, Record<string, Activity> | undefined>;
};

const initialState: EnvironmentState = {};

const environment = createSlice({
  name: "app/project/environment",
  initialState,
  reducers: {
    loadEnvironmentFromEventSuccess(
      state,
      {
        payload
      }: PayloadAction<{ environment: Environment; organizationId: string }>
    ) {
      const { environment, organizationId } = payload;
      setDeep(
        state,
        ["data", organizationId, environment.project, environment.id],
        environment
      );
    }
  },
  extraReducers: builder => {
    builder
      // LOAD LIST
      .addCase(loadEnvironments.pending, (state, { meta }) => {
        const { projectId } = meta.arg;

        delete state.errors?.project?.[projectId];
        setDeep(state, ["loading", "project", projectId], true);
      })
      .addCase(loadEnvironments.fulfilled, (state, { meta, payload }) => {
        const { organizationId, projectId } = meta.arg;

        setDeep(state, ["loading", "project", projectId], false);
        setDeep(
          state,
          ["data", organizationId, projectId],
          payload.reduce<Record<string, Environment>>((list, env) => {
            list[env.id] = env;
            return list;
          }, {})
        );
        setDeep(state, ["tree", organizationId, projectId], getTree(payload));
      })
      .addCase(loadEnvironments.rejected, (state, { meta, payload }) => {
        const { projectId } = meta.arg;

        setDeep(state, ["loading", "project", projectId], false);
        setDeep(state, ["errors", "project", projectId], payload.errors);
      })

      // LOAD ENV
      .addCase(loadEnvironment.pending, (state, { meta }) => {
        const { environmentId, projectId } = meta.arg;

        delete state.errors?.environment?.[projectId]?.[environmentId];
        setDeep(
          state,
          ["loading", "environment", projectId, environmentId],
          true
        );
      })
      .addCase(loadEnvironment.fulfilled, (state, { meta, payload }) => {
        const { environmentId, organizationId, projectId } = meta.arg;
        setDeep(
          state,
          ["loading", "environment", projectId, environmentId],
          false
        );
        setDeep(
          state,
          ["data", organizationId, projectId, environmentId],
          payload
        );
      })
      .addCase(loadEnvironment.rejected, (state, { meta, payload }) => {
        const { environmentId, projectId } = meta.arg;
        setDeep(
          state,
          ["loading", "environment", projectId, environmentId],
          false
        );
        setDeep(
          state,
          ["errors", "environment", projectId, environmentId],
          payload?.errors
        );
      })

      // UPDATE
      .addCase(updateEnvironment.pending, (state, { meta }) => {
        const { environmentId, projectId } = meta.arg;

        delete state.errors?.environmentUpdate?.[projectId]?.[environmentId];
        setDeep(
          state,
          ["loading", "environment", projectId, environmentId],
          true
        );
      })
      .addCase(updateEnvironment.fulfilled, (state, { meta, payload }) => {
        const { environmentId, organizationId, projectId } = meta.arg;

        setDeep(
          state,
          ["data", organizationId, projectId, payload.id],
          payload
        );
        setDeep(
          state,
          ["loading", "environment", projectId, environmentId],
          false
        );
      })
      .addCase(updateEnvironment.rejected, (state, { meta, payload }) => {
        const { environmentId, projectId } = meta.arg;

        setDeep(
          state,
          ["errors", "environmentUpdate", projectId, environmentId],
          getCommonError(payload?.errors).error
        );
        setDeep(
          state,
          ["loading", "environment", projectId, environmentId],
          false
        );
      })
      //DELETE
      .addCase(deleteEnvironment.pending, (state, { meta }) => {
        const { environmentId, projectId } = meta.arg;

        delete state.errors?.environmentDelete?.[projectId]?.[environmentId];
        setDeep(
          state,
          ["loading", "environment", projectId, environmentId],
          true
        );
      })
      .addCase(deleteEnvironment.fulfilled, (state, { meta }) => {
        const { environmentId, projectId } = meta.arg;

        setDeep(
          state,
          ["loading", "environment", projectId, environmentId],
          false
        );
        setDeep(
          state,
          ["deleted", "environment", projectId, environmentId],
          true
        );
      })
      .addCase(deleteEnvironment.rejected, (state, { meta, payload }) => {
        const { environmentId, projectId } = meta.arg;

        setDeep(
          state,
          ["errors", "environmentDelete", projectId, environmentId],
          payload?.errors
        );
        setDeep(
          state,
          ["loading", "environment", projectId, environmentId],
          false
        );
      })
      // Toggle activation
      .addCase(toggleEnvironmentActivation.pending, (state, action) => {
        const { environmentId, projectId } = action.meta.arg;

        delete state.errors?.environmentUpdate?.[projectId]?.[environmentId];
        setDeep(
          state,
          ["loading", "toggleActivation", projectId, environmentId],
          true
        );
      })
      .addCase(
        toggleEnvironmentActivation.fulfilled,
        (state, { meta, payload }) => {
          const { environmentId, projectId } = meta.arg;

          setDeep(
            state,
            ["loading", "toggleActivation", projectId, environmentId],
            false
          );
          setDeep(
            state,
            ["toggleActivation", projectId, environmentId],
            payload
          );
        }
      )
      .addCase(
        toggleEnvironmentActivation.rejected,
        (state, { meta, payload }) => {
          const { environmentId, projectId } = meta.arg;

          setDeep(
            state,
            ["loading", "toggleActivation", projectId, environmentId],
            false
          );
          setDeep(
            state,
            ["errors", "environmentUpdate", projectId, environmentId],
            payload.errors
          );
        }
      );
  }
});

const getTree = (environments: Environment[]) => {
  type EnvironmentNode = {
    id: string;
    parent: string | null;
    children?: EnvironmentNode[];
  };

  const dfs = (node: EnvironmentNode, parent?: Treebranch) => {
    let depth = 0;
    let path: string[] = [];
    if (parent) {
      path = nodes[parent.id] ? [...parent.path, ...[parent.id]] : [node.id];
      depth = nodes[parent.id] ? parent.depth + 1 : 1;
    }
    const current = { id: node.id, depth, path };
    tree[node.id] = current;

    if (!node.children) return;
    node.children?.forEach(child => dfs(child, current));
  };

  const tree: Record<string, Treebranch> = {};
  const nodes = environments.reduce((acc, env) => {
    acc[env.id] = { id: env.id, parent: env.parent };
    return acc;
  }, {} as Record<string, EnvironmentNode>);

  const roots = Object.values(nodes).filter(elt => elt.parent === null);

  if (!roots.length) throw new Error("No root element");

  roots.forEach(root => {
    for (const elt of Object.values(nodes)) {
      if (elt.parent === null) continue;

      const parentElt = nodes[elt.parent];
      if (parentElt) {
        // Add our current elt to its parent's `children` array
        parentElt.children = [...(parentElt.children || []), elt];
      } else {
        elt.parent = root.id;
        root.children = [...(root.children || []), elt];
      }
    }

    dfs(root);
  });
  return tree;
};

export const { loadEnvironmentFromEventSuccess } = environment.actions;
export default environment.reducer;

const selectEnv = ({ environment }: RootState) => environment;

// Tree
export const environmentTreeSelector = createSelector(
  selectEnv,
  (_: RootState, params: { organizationId: string; projectId: string }) =>
    params,
  (environment, { organizationId, projectId }) =>
    Object.values(environment.tree?.[organizationId]?.[projectId] ?? {})
);

// Environment
export const environmentSelector = createSelector(
  selectEnv,
  (
    _: RootState,
    params: {
      environmentId?: string;
      organizationId: string;
      projectId: string;
    }
  ) => params,
  (environment, { environmentId, organizationId, projectId }) => {
    if (!environmentId) return;
    return environment.data?.[organizationId]?.[projectId]?.[environmentId];
  }
);

export const environmentLoadingSelector = createSelector(
  selectEnv,
  (_: RootState, params: { environmentId?: string; projectId: string }) =>
    params,
  (environment, { environmentId, projectId }) => {
    if (!environmentId) return false;
    return (
      environment.loading?.environment?.[projectId]?.[environmentId] ?? false
    );
  }
);

export const environmentToggleActivationLoadingSelector = createSelector(
  selectEnv,
  (_: RootState, params: { environmentId?: string; projectId: string }) =>
    params,
  (environment, { environmentId, projectId }) => {
    if (!environmentId) return false;
    return (
      environment.loading?.toggleActivation?.[projectId]?.[environmentId] ??
      false
    );
  }
);

export const environmentErrorSelector = createSelector(
  selectEnv,
  (_: RootState, params: { environmentId: string; projectId: string }) =>
    params,
  (environment, { environmentId, projectId }) => {
    return environment.errors?.environment?.[projectId]?.[environmentId];
  }
);

export const environmentUpdateErrorSelector = createSelector(
  selectEnv,
  (_: RootState, params: { environmentId: string; projectId: string }) =>
    params,
  (environment, { environmentId, projectId }) => {
    return environment.errors?.environmentUpdate?.[projectId]?.[
      environmentId
    ] as CommonErrorType | undefined;
  }
);

// Project's Environments
export const environmentsSelector = createSelector(
  selectEnv,
  (_: RootState, params: { organizationId: string; projectId?: string }) =>
    params,
  (environment, { organizationId, projectId }) => {
    if (!projectId) return;
    return environment.data?.[organizationId]?.[projectId];
  }
);

export const environmentsAsArraySelector = createSelector(
  environmentsSelector,
  environments => Object.values(environments ?? {})
);

export const selectEnvironmentsOfType = createSelector(
  environmentsAsArraySelector,
  (_: RootState, params: { environmentType: string }) => params,
  (environments, { environmentType }) =>
    environments.filter(e => e.type === environmentType)
);

export const environmentsByEnvironmentTypeSelector = createSelector(
  environmentsAsArraySelector,
  environments =>
    ENVIRONMENT_TYPES.reduce<Record<string, string[]>>(
      (environmentsByType, environmentType) => ({
        ...environmentsByType,
        [environmentType]: environments
          ?.filter(environment => environment?.type === environmentType)
          ?.map(environment => environment.name)
      }),
      {}
    )
);

export const environmentsLoadingSelector = createSelector(
  selectEnv,
  (_: RootState, params: { projectId: string }) => params,
  (environment, { projectId }) =>
    environment.loading?.project?.[projectId] ?? false
);

type WithChildren<T> = T & {
  children: WithChildren<T>[];
};
const getChildren = (
  env: Treebranch,
  all: Treebranch[]
): WithChildren<Treebranch>[] => {
  return all
    .filter(elt => elt.depth === env.depth + 1 && elt.path.includes(env.id))
    .map(env => ({
      ...env,
      children: getChildren(env, all)
    }));
};

type EnvironmentInfo = { id: string; children?: EnvironmentInfo[] };
const cleanEnvsForComponent = (
  arr: WithChildren<Treebranch>[]
): EnvironmentInfo[] =>
  arr.map(e => ({
    id: e.id,
    ...(e.children.length > 0 && {
      children: cleanEnvsForComponent(e.children)
    })
  }));

export const environmentMenuSelector = (
  state: RootState,
  props: { organizationId: string; projectId: string }
) => {
  const envs = Object.values(
    state.environment?.tree?.[props.organizationId]?.[props.projectId] ?? {}
  );

  // Convert flat tree to tree
  const tree = envs
    .filter(env => env.depth === 0)
    .map(env => ({
      ...env,
      children: getChildren(env, envs)
    }));

  return cleanEnvsForComponent(tree);
};

export const environmentDeletedErrorsSelector = (
  state: RootState,
  props: { environmentId: string; projectId: string }
) => {
  return state.environment.errors?.environmentDelete?.[props.projectId]?.[
    props.environmentId
  ];
};

export const canManageEnvironmentVariablesSelector = createSelector(
  environmentSelector,
  environment => environment?.hasPermission("#manage-variables")
);
