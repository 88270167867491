import styled, { css } from "styled-components";

import { Variant, Content, LayoutProps } from "./Button.types";

const SIDE_PADDING_LG = 24;
const SIDE_PADDING_MD = 16;
const SIDE_PADDING_SM = 8;

export const __button_outline = css`
  border-color: var(
    --link-color-primary-800-border-color,
    var(--color-primary-800)
  );
  box-shadow: 0px 0px 2px
      var(--link-color-primary-800-box-shadow-color, var(--color-primary-800)),
    inset 0 0 2px
      var(--link-color-primary-800-box-shadow-color, var(--color-primary-800));
`;

export const __primary = css<LayoutProps>`
  transition-duration: 0.25s;
  transition-property: color, background-color, border-color, box-shadow;
  padding-left: ${SIDE_PADDING_LG}px;
  padding-right: ${SIDE_PADDING_LG}px;
  background-color: var(--mode-bg-primary-default);
  color: var(--theme-neutral-0);
  box-shadow: none;

  :focus {
    box-shadow: 0px 0px 0px 1px var(--theme-neutral-0),
      0px 0px 0px 2px var(--mode-bg-primary-default),
      0px 0px 2px var(--mode-bg-primary-strong);
  }

  :hover {
    background-color: var(--mode-bg-primary-strong);
  }

  :active {
    background-color: var(--mode-bg-primary-strong);
    box-shadow: none;
    border-color: transparent;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: var(--mode-bg-primary-weak);
      :focus {
        box-shadow: none;
      }
    `};
`;

export const __secondary = css<LayoutProps>`
  transition-duration: 0.25s;
  transition-property: color, border-color, box-shadow, background-color;
  padding-left: ${SIDE_PADDING_LG}px;
  padding-right: ${SIDE_PADDING_LG}px;
  background-color: transparent;
  color: var(--mode-text-neutral-default-weak);

  :focus {
    color: var(--mode-text-neutral-default-weak);
    border-color: var(--mode-border-primary-strong);
  }

  :hover {
    color: var(--mode-text-neutral-default);
    background-color: var(--mode-bg-neutral-weaker);
  }

  :active {
    color: var(--mode-text-neutral-weak);
    background-color: var(--mode-bg-primary-weaker);
    border-color: transparent;
    box-shadow: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--mode-text-neutral-weakest-disabled);
    `};
`;

export const __outline = css<LayoutProps>`
  transition-duration: 0.25s;
  transition-property: border-color, color, background-color;

  padding-left: ${SIDE_PADDING_LG}px;
  padding-right: ${SIDE_PADDING_LG}px;
  background-color: transparent;
  border-color: var(--mode-border-neutral-default);
  color: var(--mode-text-primary-default);

  :focus {
    border-color: var(--mode-border-primary-strong);
    color: var(--mode-text-primary-default);
  }

  :hover {
    color: var(--theme-neutral-0);
    border-color: transparent;
    background-color: var(--mode-bg-primary-default);
    );
  }

  :active {
    color: var(--theme-neutral-0);
    background-color: var(--mode-bg-primary-strong);
    border-color: transparent;
    box-shadow: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--mode-text-primary-weakest);
      border-color: var(--mode-border-neutral-default);
    `};
`;

const SIDE_PADDING_LINK = 0;

export const __link = css<LayoutProps>`
  padding-left: ${SIDE_PADDING_LINK}px;
  padding-right: ${SIDE_PADDING_LINK}px;
  transition-duration: 0.25s;
  transition-property: border-color, box-shadow, color;
  font-weight: 400;
  color: var(--mode-text-primary-default);
  background-color: transparent;
  height: 24px;
  position: relative;

  :after {
    transition-duration: 0.25s;
    transition-property: background-color;
    content: "";
    position: absolute;
    top: 100%;
    left: ${SIDE_PADDING_LINK}px;
    height: 1px;
    width: calc(100% - ${SIDE_PADDING_LINK * 2}px);
    background-color: transparent;
  }

  :focus {
    border-color: var(--mode-border-primary-strong);
  }

  :hover {
    color: var(--mode-text-primary-strong);
  }

  :hover:after {
    background-color: var(--mode-border-primary-strong);
  }

  :active {
    border-color: transparent;
    box-shadow: none;
    color: var(--mode-text-primary-strong);
  }

  :active:after {
    background0color: var(--mode-border-primary-strong);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--mode-text-primary-weakest);
    `};
`;

export const __critical = css<LayoutProps>`
  transition-duration: 0.25s;
  transition-property: border-color, color, background-color;

  padding-left: ${SIDE_PADDING_LG}px;
  padding-right: ${SIDE_PADDING_LG}px;
  background-color: transparent;
  border-color: var(--mode-border-neutral-default);
  color: var(--mode-text-critical-default);

  :focus {
    border-color: var(--mode-border-critical-default);
    box-shadow: 0px 0px 2px var(--mode-border-critical-default),
      inset 0 0 2px var(--mode-border-critical-default);
  }

  :hover {
    color: var(--theme-neutral-0);
    background-color: var(--mode-bg-critical-default);
    border-color: var(--mode-bg-critical-default);
  }

  :active {
    background-color: var(--mode-bg-critical-strong);
    border-color: var(--mode-bg-critical-strong);
    box-shadow: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      border-color: transparent;
      color: var(--mode-text-critical-weakest);
    `};
`;

export const __mixed = css<LayoutProps>`
  padding-left: ${SIDE_PADDING_SM}px;
  padding-right: ${SIDE_PADDING_MD}px;

  > :first-child {
    display: inline-block;
    margin-right: 8px;
  }

  svg {
    color: currentColor;
  }

  svg * {
    fill: currentColor;
  }

  ${({ variant }) =>
    variant === Variant.link &&
    css`
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      padding-left: 0px;
      padding-right: 0px;

      > :first-child {
        margin-right: 4px;
      }
    `}

  ${({ variant, disabled }) =>
    variant === Variant.link &&
    disabled &&
    css`
      color: var(--mode-text-primary-weakest);
    `};
`;

export const __icon = css<LayoutProps>`
  height: 32px;
  width: 32px;
  padding: 0;

  > :first-child {
    display: inline-block;
    height: 24px;
    width: 24px;
  }

  svg {
    color: currentColor;
  }

  svg * {
    fill: currentColor;
  }
`;

const __defaultDisabled = css`
  cursor: not-allowed;
  pointer-events: none;
`;

export const Layout = styled.button.attrs<LayoutProps>(props => ({
  type: props.type || "button",
  "data-ds": true
}))<LayoutProps>`
  box-sizing: border-box;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  ${({ capitalized }) =>
    capitalized === "true" &&
    css`
      text-transform: capitalize;
    `}

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  mix-blend-mode: normal;
  border-radius: var(
    --base-button-button-border-radius,
    var(--button-border-radius-small-border-radius, var(--border-radius-small))
  );
  cursor: pointer;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:first-letter {
    text-transform: uppercase;
  }
  /* button style resets */
  outline: none;
  border: 1px solid transparent;
  padding: 0;
  margin: 0;

  /* anchor style resets */
  text-decoration: none;

  ${({ disabled }) => disabled && __defaultDisabled};

  ${({ variant }) => variant === Variant.primary && __primary};
  ${({ variant }) => variant === Variant.secondary && __secondary};
  ${({ variant }) => variant === Variant.outline && __outline};
  ${({ variant }) => variant === Variant.link && __link};
  ${({ content }) => content === Content.mixed && __mixed};
  ${({ content }) => content === Content.icon && __icon};
  ${({ variant }) => variant === Variant.critical && __critical};
`;
