import {
  listTeamProjectAccesses,
  ListTeamProjectAccessesOptions
} from "Api/teams/projectAccesses/listTeamProjectAccesses.request";
import { paginatedEndpointSliceFactory } from "Reducers/sliceFactory";

type Options = {
  teamId: string;
  options?: ListTeamProjectAccessesOptions;
};

const { slice, hooks } = paginatedEndpointSliceFactory(
  "teamProjectAccesses",
  (options: Options) =>
    listTeamProjectAccesses(options.teamId, {
      sort: "project_title",
      page: { size: "200" },
      ...options.options
    }),
  options => options.teamId,
  // eslint-disable-next-line @typescript-eslint/require-await
  async response => response
);

export const teamProjectAccessesReducer = slice.reducer;

export const {
  useDataByKey: useTeamProjectAcccesses,
  useInvalidateKey: useInvalidateTeamProjectAccesses
} = hooks;
