// TODO: Move to constants?
export const FEATURE_FLAGS_LOCAL_STORAGE_KEY =
  "platform-sh-console-feature-flags";

export const FEATURE_FLAGS = [
  "ENABLE_ACCOUNT_PAGES",
  "ENABLE_ACCOUNT_SECURITY_ROUTE",
  "ENABLE_ADD_DOMAIN_SUPPORT",
  "ENABLE_ADD_PROJECT_USER_PRICING_AGREEMENT",
  "ENABLE_ANNOUNCEMENTS",
  "ENABLE_AUTOMATED_BACKUPS",
  "ENABLE_BILLING",
  "ENABLE_BLACKFIRE_CARD",
  "ENABLE_CONNECTED_ACCOUNTS_ROUTE",
  "ENABLE_CREATE_PROJECT",
  "ENABLE_CHANGE_USERNAME_EMAIL",
  "ENABLE_DEPLOYMENT_OVERLAY",
  "ENABLE_EDIT_PLAN",
  "ENABLE_ENVIRONMENT_DOMAINS",
  "ENABLE_ENVIRONMENT_EMAIL_SETTING",
  "ENABLE_EXTERNAL_URL",
  "ENABLE_FIRST_PROJECT_INCENTIVE",
  "ENABLE_FREE_TRIAL_DIALOG",
  "ENABLE_GREEN_DISCOUNT",
  "ENABLE_HIPAA_COMPLIANCE",
  "ENABLE_OBSERVABILITY_SUITE_INTEGRATIONS",
  "ENABLE_ORGANIZATION",
  "ENABLE_CREATE_ORGANIZATION",
  "ENABLE_PROJECT_SETUP_WIZARD",
  "ENABLE_PROJECT_PROVISIONING_WIZARD",
  "ENABLE_PROJECT_SETUP_TIPS",
  "ENABLE_RESET_PROFILE_PASSWORD",
  "ENABLE_SENTRY",
  "ENABLE_SERVICE_TREE",
  "ENABLE_SOURCE_OPERATION",
  "ENABLE_STAFF_TEST_MODE",
  "ENABLE_SUBSCRIBE_FLOW",
  "ENABLE_UNBRANDED_ILLUSTRATIONS",
  "ENABLE_USER_MANAGEMENT",
  "ENABLE_USAGE_BASED_BILLING_PAGES",
  "ENABLE_ORGANIZATION_FIRST_ONBOARDING",
  "ENABLE_MIGRATION_FLOW",
  "ENABLE_NOTIFICATION_SETTINGS",
  "ENABLE_HTTP_METRICS",
  "ENABLE_VOUCHERS",
  "ENABLE_PROFILING",
  "ENABLE_RESOURCE_METRICS_THRESHOLD",
  "DISABLE_ALL_PROJECTS",
  "ENABLE_ORGANIZATION_PLANS_PERMISSION",
  "ENABLE_CUSTOM_LEFT_PANEL",
  "ENABLE_LOCAL_DEPLOYMENT_TARGET",
  "ENABLE_TOTAL_RESOURCE_ALLOCATION_METRICS",
  "ENABLE_CONTINUOUS_PROFILING_SELLABLE",
  "ENABLE_PROJECT_ACCESS_SELECT",
  "ENABLE_SUPPORT_SELLABLE",
  "ENABLE_OBSERVABILITY_SANDBOX",
  "ENABLE_CUSTOM_BANNER_MESSAGE",
  "ENABLE_SOURCE_REPOSITORY_TOOLTIP",
  "ENABLE_ENVIRONMENT_SOCKET",
  "ENABLE_DISCLAIMER_GENERAL_SUPPORT",
  "ENABLE_REACTIVATE_TRIAL"
] as const;

export type FeatureFlagName = typeof FEATURE_FLAGS[number];

export type FeatureFlags = Record<FeatureFlagName, boolean>;
