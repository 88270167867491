/**
 * Generates a union of all possible property paths in an object.
 */
export type PathOf<Obj extends object> = {
  [Key in keyof Obj & string]: NonNullable<Obj[Key]> extends object
    ? [Key] | [Key, ...PathOf<NonNullable<Obj[Key]>>]
    : [Key];
}[keyof Obj & string];

/**
 * Checks that property exists on an object and returns it.
 * Type requirements are intentionally kept loose.
 */
type GetValue<Obj, K> = K extends keyof Obj ? Obj[K] : never;

/**
 * Recursively travels the provided path through the object to return the final property's type.
 * Types are necessarily loose because of the recursion.
 * This type should not be used directly. The strongly typed `ValueAt` should be used instead.
 */
type _ValueAt<Obj extends object, Path extends any[]> = Path extends [
  infer Key,
  ...infer Rest
]
  ? Rest extends []
    ? GetValue<Obj, Key>
    : NonNullable<GetValue<Obj, Key>> extends object
    ? _ValueAt<NonNullable<GetValue<Obj, Key>>, Rest>
    : never
  : never;

/**
 * Strongly typed wrapper for `_ValueAt`.
 */
export type ValueAt<Obj extends object, Path extends PathOf<Obj>> = _ValueAt<
  Obj,
  Path
>;

/**
 * Similar to `PathOf`, but this type only allows paths that resolve to a provided type.
 * See usages for more context.
 * Example:
 *  `PathWithType<{ one: 1; a: "a"; nested: { two: 2; b: "b" } }, number>`
 *  results in ["one"] | ["nested", "two"];
 *  i.e. only the paths into of the object which result in numbers.
 */
export type PathWithType<
  Obj extends object,
  T,
  Path extends PathOf<Obj> = PathOf<Obj>
> = Path extends string[]
  ? ValueAt<Obj, Path> extends T
    ? Path
    : never
  : never;

/**
 * Sets the value at a deeply nested path in an object, similar to Immutable.js's Map.set().
 * Crucially, creates new objects at empty paths.
 */
export const setDeep = <Obj extends object, Path extends PathOf<Obj>>(
  obj: Obj,
  path: Path,
  value: ValueAt<Obj, Path>
) => {
  const set = (o: any, p: any[], v: any) => {
    const [key, ...rest] = p;
    if (rest.length > 0) {
      if (!o[key]) {
        o[key] = {};
      }

      set(o[key], rest, v);
    } else {
      o[key] = v;
    }
  };

  set(obj, path, value);
};
